@import "./variables.scss";
// for form inputs styles
// use custom widths in respective components
%form-inputs {
  background-clip: padding-box;
  background-color: white;
  border: 1px solid lightgrey;
  box-sizing: border-box;
  color: $color-primary;
  display: block;
  font-family: $font;
  font-size: 1rem;
  font-weight: 400;
  height: 2.9em;
  line-height: 1.5;
  margin: 0.25em 0;
  padding: 0.7em;
}

//for buttons and React Links that look like buttons
%button-shared {
  background-color: $color-form-lightgrey;
  border-color: $color-form-lightgrey; 
  color: black;
  font-size: 1em;
  font-weight: bold;
  height: 100%;
  line-height: 1.5;
  margin: .5em 0;
  min-height: 33px;
  min-width: 100px;
  padding: 4px 6px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  width: 100%;
  height: 60px;
}
