@import "./_partials.scss";

.auth-form {
  width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  .was-validated .form-control:invalid,
  .form-control .is-invalid,
  .invalid-feedback {
    border-color: #dc3545;
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    color: red;
    max-width: 500px;
    margin-top: -5px;
    text-align: center;
  }
  .form-control {
    @extend %form-inputs;

    height: calc(1.5em + 0.75rem + 2px);
    margin: 1rem 0;
    padding: 1.5rem 0.75rem;
    width: 100%;
  }
}
