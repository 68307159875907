@import url("https://fonts.googleapis.com/css?family=Montserrat:900&display=swap");
@import "./reset.scss";
@import "_variables.scss";
// import scss files below

body {
  background: $color-background;
  font-family: $font;
  -webkit-text-size-adjust: 100%;
  color: #474747;
  overflow-y: scroll;
}

h1 {
  font-weight: bold;
  font-size: 28px;
  line-height: 140%;
}

h2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
}

h3 {
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
}

p {
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
}

cite {
  font-style: italic;
}

blockquote {
  font-weight: 700;
  background-color: #92cc4e;
  color: #474747;
  padding: 1em 2em;
  margin-bottom: 1em;
  font-size: 1.25em;
  line-height: 1.25em;
}

strong {
  font-weight: bold;
}

code {
  font-family: monospace;
}

pre {
  display: block;
  font-family: monospace;
  white-space: pre;
  margin: 1em 0px;
}

a {
  color: blue;
  text-decoration: underline;
  outline: 0;
  cursor: pointer;
}

fieldset {
  border: 1px solid #e0e0e0;
  padding: 1.5em;
  margin: 0;
}

legend {
  display: block;
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}

abbr {
  border-bottom: 1px dotted #e0e0e0;
  cursor: help;
  color: #cc4e10;
}

button {
  font-family: $font;
  font-weight: bold;
  font-size: 16px;
}

input,
select,
textarea {
  font-family: $font;
  font-size: 16px;
  margin: 0 1px 0;
}

input[type="checkbox"],
input[type="radio"] {
  width: auto;
  height: 1.3em;
  width: 1.3em;
}

// default styles for  links, buttons, etc here?
// import individual scss files below
#root {
  color: $color-primary;
  background-color: $color-background;
}

#ladot-logo {
  margin: 23px;
}

input,
textarea {
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 0.5em 0.65rem;
}

button.link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: white;
  cursor: pointer;
  text-decoration: none;
  outline: 0;
}

.is-valid {
  border-color: #326e1e;
}

.is-error {
  border-color: #cc2a14;
}

.hide {
  display: none;
}

.center {
  margin: 0 auto;
}

.background-gray {
  background: #e7ebf0;
}

.border-gray {
  border: 1px solid #e7ebf0;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.colorGray {
  color: rgba(15, 41, 64, 0.5);
}

.highlightPage {
  background: #ebeff3;
}

.hoverPointer:hover {
  cursor: pointer;
}

// add any override to the default styles in the individual component styles
@import "./AuthForms.scss"; // Login and Register components
